import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Nav } from '@keydotco/key-components';

import { track } from '../../services/analytics';
import { generateHref } from '../../services/util';
import './Nav.css';

class NavBar extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    loggedIn: PropTypes.bool,
    openLogin: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    stepAdvanced: PropTypes.bool.isRequired,
    analyticsContext: PropTypes.object,
  };

  static defaultProps = {
    loggedIn: false,
    analyticsContext: {},
  };

  state = {
    pageScrolledDown: window.scrollTop > 0,
  };

  componentDidMount() {
    document.body.style.overflow = 'auto';
    window.addEventListener('scroll', this.updateClasses, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateClasses, true);
  }

  trackLinkClick = (cta) => track('link_click',
    { cta, section: 'KEY Header' },
    {
      hostname: window.location.hostname,
      ...this.props.analyticsContext,
    });

  onClickSignIn = () => {
    this.props.openLogin();
  };

  onClickSignOut = () => {
    this.props.logOut();
  };

  updateClasses = () => {
    /* eslint-disable compat/compat */
    this.setState({ pageScrolledDown: (window.scrollY > 0) });
  };

  render() {
    const navLinks = {
      'Explore Homes': {
        href: generateHref(`${process.env.ITINERARY_ENDPOINT}/listings`),
      },
      'Luxury Getaways': {
        'Beach Getaways': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/beach`),
        },
        'Desert Getaways': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/desert`),
        },
        'Lake Getaways': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/lakes`),
        },
        'Mountain Getaways': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/mountains`),
        },
        'Ski Getaways': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/ski`),
        },
      },
      'Partner with KEY': {
        'Property Managers': {
          href: generateHref(`${process.env.UNLOCK_SITE_ENDPOINT}/property-manager/`),
        },
        'Travel Agents': {
          href: generateHref(`${process.env.CORPORATE_SITE_ENDPOINT}/travel-agents/`),
          openInNewTab: true,
        },
        'Brand Partners': {
          href: generateHref(`${process.env.UNLOCK_SITE_ENDPOINT}/brand-partners/`),
        },
        'Content Homes': {
          href: generateHref(`${process.env.ITINERARY_ENDPOINT}/stays/content-homes`),
        },
      },
      'My Trips': {
        href: generateHref(`${process.env.ITINERARY_ENDPOINT}/itineraries`),
      },
      'Sign In': {
        isVisible: !this.props.loggedIn,
        onClick: this.onClickSignIn,
      },
      'Sign Out': {
        isVisible: this.props.loggedIn,
        onClick: this.onClickSignOut,
      },
    };

    return (
      <Nav
        className={`main-nav ${this.props.stepAdvanced ? 'static' : 'fixed'} ${this.state.pageScrolledDown ? 'scrolled-down' : 'at-top'}`}
        pageDocument={document}
        links={navLinks}
        trackLinkClick={this.trackLinkClick}
        logoLinkUrl={generateHref(process.env.KEY_SITE_ENDPOINT)}
      />
    );
  }
}

export default withRouter(NavBar);
