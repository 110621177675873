import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';
import { withRouter } from 'react-router-dom';
import { Footer } from '@keydotco/key-components';

import NavBar from '../../components/NavBar';
import SEO from '../../components/SEO';
import { checkUser, setLeadId } from '../../data/user/actions';
import { getLeadBySalesforceID } from '../../data/activeItinerary/actions';

class App extends React.Component {
  static propTypes = {
    checkUser: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      loggedIn: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.checkUser();
  }

  render() {
    const renderAimLogicPixel = () => {
      if (process.env.APP_ENV !== 'production') return null;
      const scripts = [
        {
          type: 'text/javascript',
          content: 'const pixelId = \'61ce553b-5a43-4bdc-9fbf-d3cc3a3b5baf\';',
        },
        {
          type: 'text/javascript',
          src: 'https://cdn.identitypxl.app/pixel_V2.js',
        },
      ];

      return scripts.map(({ content, ...props }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <script key={`aim-logic-${idx}`} {...props}>
          {content}
        </script>
      ));
    };

    return (
      <span>
        <SEO
          title="Luxury Vacation Rentals & Personalized Services | KEY.co"
          description="Find your perfect getaway with KEY.co. Explore curated vacation homes with in-home services like pre-arrival groceries and private chefs. Our handpicked homes offer premium amenities for a truly tailored vacation experience."
          imageUrl="https://static.key.co/img/home/backyard-dinner.jpeg"
          doesSendPageEvent={false}
          url={process.env.KEY_SITE_ENDPOINT}
        >
          {renderAimLogicPixel()}
        </SEO>
        <Notifications notifications={this.props.notifications} />
        <div className="content">
          <NavBar pathname={this.props.location.pathname} />
          <div className="content-children">
            { this.props.children }
          </div>
        </div>
        <Footer />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  checkUser: () => dispatch(checkUser()),
  setLeadId: (id) => dispatch(setLeadId(id)),
  getLeadBySalesforceID: (id) => dispatch(getLeadBySalesforceID(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
